<template>
<loader v-bind="{ loading }">
    <form>
        <columns>
            <column>
                <text-input classes="is-medium is-rounded" :value="equipment.name" @input="updateName" :error="$root.errors.name">
                    Name
                </text-input>
            </column>
            <column>
                <data-selector 
                    classes="is-medium is-rounded"
                    :items="equipment_classification_list"
                    label-key="name"
                    value-key="uuid"
                    :value="equipment.classification_uuid" 
                    @input="updateClassification" 
                    :error="$root.errors.classification_uuid">
                    Classification
                </data-selector>
            </column>
            <column>
                <data-selector 
                    classes="is-medium is-rounded"
                    :items="user_list"
                    label-key="full_name"
                    value-key="uuid"
                    :value="equipment.user_uuid" 
                    @input="updateUser" 
                    :error="$root.errors.user_uuid">
                    Assigned User
                </data-selector>
            </column>
        </columns>

        

        <div v-if="activeClassification && activeClassification.meta">
            <columns>
                <column>
                    <h4 class="title is-4">Properties</h4>
                    <p v-if="!activeClassification.meta.length">
                        This Equipment Classification does not have properties assigned.
                    </p>
                </column>
            </columns>
            <columns v-for="property in activeClassification.meta" :key="property.uuid">
                <column>
                    <edit-equipment-property :property="property"/>
                </column>
            </columns>
        </div>

        <columns>
            <column>
                <submit-button
                    class="is-medium is-rounded"
                    :working="working"
                    @submit="save">
                    Save
                </submit-button>
            </column>
        </columns>
    </form>
</loader>
</template>
<script>
import find from 'lodash/find'
import { mapGetters, mapMutations } from 'vuex'
import EditEquipmentProperty from './partials/EditEquipmentProperty.vue'

export default {

    components: {
        EditEquipmentProperty
    },

    data: () => ({
        loading: true,
        working: false
    }),

    async beforeCreate() {
        await this.$store.dispatch('equipmentClassification/loadList')
        await this.$store.dispatch('user/loadList')

        this.loading = false
    },

    methods: {
        ...mapMutations('equipment', [
            'updateName',
            'updateClassification',
            'updateUser'
        ]),
        save() {
            this.working = true
            this.$store.dispatch('equipment/save').then(() => {
                this.working = false
                this.$toast.success('Saved')
            }).catch(() => this.working = false)
        }
    },
    
    computed: {
        ...mapGetters('equipment', [
            'equipment'
        ]),
        ...mapGetters('equipmentClassification', [
            'equipment_classification_list'
        ]),
        ...mapGetters('user', [
            'user_list'
        ]),
        activeClassification() {
            return find(this.equipment_classification_list, classification => classification.uuid === this.equipment.classification_uuid)
        }
    }

}
</script>